import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { StandardPageTemplate } from "../../templates/StandardPageTemplate/StandardPageTemplate";
import { GoogleLoginButton } from "./GoogleLoginButton/GoogleLoginButton";
import { LoginForm } from "./LoginForm/LoginForm";
export const LoginPage: React.FC = () => {
    const [t] = useTranslation();
    const title = t("loginPage.title");
    const description = t("loginPage.description");
    return (
        <StandardPageTemplate title={title} description={description} canonical={HeadbotRoute.Login}>
            <LoginForm />
            <GoogleLoginButton />
        </StandardPageTemplate>
    );
};
